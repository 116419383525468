import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-993534fc"), n = n(), _popScopeId(), n);

const _hoisted_1 = ["id"];
import { ref, watch } from "vue";
export default {
  __name: 'CommonBtn',
  props: {
    text: {
      default: "确认",
      type: String
    },
    id: {
      default: "",
      type: String
    },
    width: {
      default: "80px",
      type: String
    },
    height: {
      default: "30px",
      type: String
    },
    params: {
      default: null,
      type: Object
    },
    type: {
      default: "primary",
      type: String
    },
    loading: {
      default: false,
      type: Boolean
    }
  },
  emits: ["handleClick"],

  setup(__props, {
    emit
  }) {
    const props = __props;

    const _loading = ref(false);

    watch(() => props.loading, (newVal, oldVal) => {
      _loading.value = newVal;
    });

    const emitClick = () => {
      if (props.params) {
        emit("handleClick", props.params);
      } else {
        emit("handleClick");
      }
    };

    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        id: __props.id,
        class: _normalizeClass(['if-common-btn', __props.type === 'plain' ? 'plain' : '', _loading.value ? 'loading' : '']),
        style: _normalizeStyle({
          width: __props.width,
          height: __props.height,
          lineHeight: __props.height
        }),
        onClick: emitClick
      }, _toDisplayString(__props.text), 15, _hoisted_1);
    };
  }

};